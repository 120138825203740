import React from 'react';
import { Box, Note } from '@contentful/f36-components';
import { TestIds } from '../fixtures/constants';
import { MAX_ALGOLIA_SIZE_IN_BYTES } from './AlgoliaFilterErrorMessage';

export const ErrorScopeTooBig = (): React.JSX.Element => {
  return (
    <Box marginBottom="spacingM">
      <Note variant="negative" data-test-id={TestIds.ERROR_SCOPE_TOO_BIG}>
        {`Scope too big. Scope cannot be bigger than ${MAX_ALGOLIA_SIZE_IN_BYTES} bytes`}
      </Note>
    </Box>
  );
};

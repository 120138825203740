import React from 'react';
import Parser from 'algolia-filters-js-syntax-validator';
import { ErrorScopeTooBig } from './ErrorScopeTooBig';
import { ErrorGeneratingScope } from './ErrorGeneratingScope';

export const MAX_ALGOLIA_SIZE_IN_BYTES = 16_384;

interface AlgoliaFilterErrorMessageProps {
  algoliaFilter: string;
}

const AlgoliaFilterErrorMessage = (props: AlgoliaFilterErrorMessageProps): React.JSX.Element => {
  const validateScope = (algoliaFilter: string): { errorMessage: string; html: string } => {
    const parser = new Parser();
    return parser.parse(algoliaFilter);
  };

  const hasValidSize = (): boolean => {
    const size = new Blob([props.algoliaFilter]).size;
    return size < MAX_ALGOLIA_SIZE_IN_BYTES;
  };

  const { errorMessage, html } = validateScope(props.algoliaFilter);

  const ValidationMessage = () => {
    if (!hasValidSize()) {
      return <ErrorScopeTooBig />;
    } else if (errorMessage) {
      return <ErrorGeneratingScope errorMessage={errorMessage} html={html} />;
    } else {
      return <></>;
    }
  };

  return ValidationMessage();
};

export default AlgoliaFilterErrorMessage;

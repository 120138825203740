import React from 'react';
import { Box, Note } from '@contentful/f36-components';
import { TestIds } from '../fixtures/constants';

export const DataNotFound = ({ filter }: { filter: string }): React.JSX.Element => {
  return (
    <Box marginBottom="spacingM">
      <Note variant="negative" data-test-id={TestIds.ERROR_RETRIEVING_DATA}>
        {`Error when retrieving the full list of ${filter}. Please reload the page after a couple of minutes and, if the
        issue continues, contact us at`}{' '}
        <a href="https://vistaprint.slack.com/archives/C07C7FLQ10B" target="_blank" rel="noreferrer">
          #team-product-search
        </a>{' '}
        in Slack
      </Note>
    </Box>
  );
};

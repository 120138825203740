import React from 'react';
import { Accordion, Box, Note } from '@contentful/f36-components';
import { TestIds } from '../fixtures/constants';
import DOMPurify from 'dompurify';

interface ErrorGeneratingScopeProps {
  errorMessage: string;
  html: string;
}

export const ErrorGeneratingScope = (props: ErrorGeneratingScopeProps): React.JSX.Element => {
  return (
    <Box marginBottom="spacingM">
      <Note variant="negative" data-test-id={TestIds.ERROR_GENERATING_SCOPE}>
        {`Error: ${props.errorMessage}. Please contact with `}
        <a href="https://vistaprint.slack.com/archives/C07C7FLQ10B" target="_blank" rel="noreferrer">
          #team-product-search
        </a>{' '}
        in Slack.
      </Note>
      <Accordion>
        <Accordion.Item title="Error detail" data-test-id={TestIds.ERROR_DETAIL_GENERATING_SCOPE}>
          <pre style={{ textWrap: 'wrap' }}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html) }} />
          </pre>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

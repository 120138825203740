export const TestIds = {
  CATEGORIES_SELECTOR: 'categories-selector',
  PRODUCTS_SELECTOR: 'product-selector',
  ATTRIBUTES_SELECTOR: 'attributes-selector',
  PRODUCT_PILL: 'product-pill',
  ATTRIBUTE_VALUE_PILL: 'attribute-value-pill',
  ATTRIBUTE_VALUE: 'attribute-label',
  ERROR_RETRIEVING_DATA: 'error-retrieveing-data',
  ERROR_SCOPE_TOO_BIG: 'error-too-big',
  ERROR_GENERATING_SCOPE: 'error-generating-scope',
  ERROR_DETAIL_GENERATING_SCOPE: 'error-detail-generating-scope',
} as const;

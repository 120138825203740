import React, { useEffect, useState } from 'react';
import { ErrorDPSNotWorking } from './ErrorDPSNotWorking';

interface PreviewProps {
  previewFiltersUrl: string;
  defaultLocale: string;
  locale?: string;
  hasError: boolean;
}

const localeDomain: Record<string, string> = {
  'en-IE': 'www.vistaprint.ie',
  'en-US': 'www.vistaprint.com',
  'en-IN': 'www.vistaprint.in',
  'en-AU': 'www.vistaprint.co.nz',
};

const buildPreviewUrl = (previewFiltersUrl: string, domain: string): string => {
  return `https://${domain}/search?isEmbedded&previewFilters=${encodeURIComponent(previewFiltersUrl)}`;
};

const Preview = (props: PreviewProps): React.JSX.Element => {
  const urlDomain: string = localeDomain[props.defaultLocale] ?? localeDomain['en-US'];

  const [previewUrl, setPreviewUrl] = useState<string>(buildPreviewUrl(props.previewFiltersUrl, urlDomain));

  useEffect(() => {
    setPreviewUrl(buildPreviewUrl(props.previewFiltersUrl, urlDomain));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.previewFiltersUrl]);

  return (
    <>
      {props.hasError ? (
        <ErrorDPSNotWorking />
      ) : (
        <div id="wrap">
          <iframe title="preview" frameBorder={0} id="frame" src={previewUrl} width="100%" height="100%"></iframe>
        </div>
      )}
    </>
  );
};

export default Preview;

import { Box, Heading } from '@contentful/f36-components';
import React from 'react';
import { ScopeDefinition } from 'src/model/ScopeDefinition';

import './DebugData.css';

interface DebugDataProps {
  scopeDefinition: ScopeDefinition;
  algoliaFilter: string;
}

const DebugData = (props: DebugDataProps): React.JSX.Element => {
  return (
    <>
      <Box padding="spacingL">
        <Heading>Debug</Heading>
        <div>
          <pre style={{ textWrap: 'pretty' }}>{JSON.stringify(props.scopeDefinition, null, 2)}</pre>
        </div>
      </Box>

      <Box padding="spacingL">
        <Heading>Algolia Filter</Heading>
        <div>
          <pre style={{ textWrap: 'pretty' }}>{JSON.stringify(props.algoliaFilter, null, 2)}</pre>
        </div>
      </Box>
    </>
  );
};

export default DebugData;
